import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PageNosotros = props => {

  return (
    <Layout {...props}>
      <Seo title='Nosotros' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='¡Bienvenidos a Cashfy!'
          subheader='
          
          

          En un mundo en constante cambio y desafíos financieros, contar con información precisa y actualizada es esencial para tomar decisiones inteligentes en tus inversiones y finanzas personales. En Cashfy, comprendemos la importancia de estar al tanto de las últimas tendencias y novedades financieras en Argentina y cómo pueden afectar tus decisiones financieras. Por eso, estamos aquí para ser tu fuente confiable de información financiera local.
          <br><br>
          <b>¿Quiénes Somos?</b>
          <br><br>
          En Cashfy, somos un equipo apasionado de expertos en finanzas y economía con un profundo conocimiento del mercado argentino. Nuestra misión es proporcionarte contenido de alta calidad que te ayude a comprender mejor los desafíos y oportunidades financieras que enfrentas en Argentina.
          <br><br>
          <b>Lo Que Ofrecemos</b>
          <br><br>
          Nuestro blog está diseñado para ser una fuente de información valiosa y educativa sobre una amplia gama de temas financieros en Argentina, incluyendo:
          <br><br>
          Análisis de Mercado: Te brindamos análisis actualizados del mercado financiero argentino, lo que te ayudará a tomar decisiones informadas en tus inversiones y a entender cómo los eventos económicos globales pueden afectar tu situación financiera local.
          <br><br>
          Consejos de Inversión: Ofrecemos consejos y estrategias de inversión adaptados a las condiciones del mercado argentino, ayudándote a optimizar tu cartera de inversiones.
          <br><br>
          Finanzas Personales: Exploramos temas relacionados con la gestión del dinero, el ahorro, el presupuesto y la planificación financiera personal en el contexto argentino.
          <br><br>
          Noticias y Actualizaciones: Mantente al día con las noticias económicas y financieras más recientes en Argentina y cómo pueden impactar tu vida financiera.
          <br><br>
          Educación Financiera: Creemos en empoderarte con el conocimiento necesario para tomar decisiones financieras inteligentes. Publicamos guías y tutoriales para mejorar tu alfabetización financiera.
          <br><br>
          <b>Nuestro Compromiso Contigo</b>
          <br><br>
          En Cashfy, nos comprometemos a proporcionarte información precisa, imparcial y relevante para ayudarte a navegar por el complejo mundo de las finanzas argentinas. Valoramos la transparencia y la confiabilidad, y siempre estamos dispuestos a responder a tus preguntas y comentarios.
          <br><br>
          No importa si eres un inversionista experimentado o alguien que recién comienza a explorar el mundo de las finanzas, Cashfy está aquí para ser tu guía en el viaje hacia el éxito financiero en Argentina.
          <br><br>
          <b>¡Unite a Nuestra Comunidad!</b>
          <br><br>
          Te invitamos a unirte a nuestra comunidad financiera en constante crecimiento. Suscribite a nuestro boletín para recibir las últimas actualizaciones directamente en tu bandeja de entrada y seguinos en nuestras redes sociales para mantenernos en contacto.
          <br><br>
          En Cashfy, creemos que el conocimiento financiero es el camino hacia una vida financiera más sólida y segura. Te damos la bienvenida a explorar nuestro blog y a ser parte de nuestra comunidad dedicada a hacer crecer tu riqueza y asegurar tu futuro financiero en Argentina.
          <br><br>
          '
        />
      </Stack>

    </Layout>
  )
}

export default PageNosotros
